@font-face {
  font-family: "FERIFont";
  src: url("./assets/fonts/FERIFont.ttf") format("truetype");
}

html,
body,
#root {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: FERIFont;
  user-select: none;
}

.app {
  height: 100%;
  background-color: #005f85;
}

.text-feri {
  color: #005f85;
}

.bg-feri {
  background-color: #005f85;
}

.border-feri {
  border: 1px solid #005f85 !important;
}

.border-top-feri {
  border-top: 1px solid #005f85 !important;
}

.floor-img {
  height: 200px;
  margin-top: -70px;
  /* transform: skew(30deg, -20deg) scale(1.2); */
}

.floor-text {
  max-height: 70px;
}

.floor-container {
  max-width: 540px !important;
  border-left: 2px solid #005f85;
  margin-left: 10px;
}

.vertical-center {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.calendar-page {
  text-align: center;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 33vh;
}

.calendar-title {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.calendar-controls {
  margin-bottom: 1rem;
}

.calendar {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 0.5rem;
  min-height: 20rem;
  overflow: auto; /* Add this line to enable scrolling if the calendar exceeds the height */
}

.date {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  padding: 0.5rem;
  background-color: #f2f2f2;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.date:hover {
  background-color: #d9d9d9;
}

.selected {
  background-color: #007bff;
  color: #fff;
}

.selected:hover {
  background-color: #0056b3;
}

.selected-date {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.selected-date h2 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  color: #007bff;
}

.selected-date p {
  font-size: 1rem;
  margin-bottom: 0;
  color: #333;
}

.schedule-entry {
  background-color: #d9d9d9;
  padding: 16px;
  margin-bottom: 16px;
}

.profile-image {
  border: 5px solid black;
  padding: 10px;
  margin: auto;
  border-radius: 100%;
}

.class-name {
  font-weight: bold;
  font-size: 16px;
}

.location {
  margin-top: 8px;
  font-size: 14px;
}

.time {
  text-align: right;
  margin-top: 8px;
  font-size: 14px;
}

.professor {
  margin-top: 8px;
  font-size: 14px;
}

.schedule-entry {
  font-family: "Montserrat", sans-serif;
}

.content-container {
  max-height: 80vh; /* Adjustable height!! */
  overflow-y: auto;
}

/* For WebKit-based browsers */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #005f85;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #007bff;
}

/* For Firefox */
.scroll-container {
  scrollbar-width: thin;
}

.scroll-container::-moz-scrollbar {
  width: 10px;
}

.scroll-container::-moz-scrollbar-thumb {
  background-color: #005f85;
}

.scroll-container::-moz-scrollbar-thumb:hover {
  background-color: #007bff;
}

.map-container .react-transform-wrapper {
  width: 100%;
}

.map-wrapper {
  width: 100%;
  height: 80%;
  overflow: auto;
}

.map-container svg {
  width: 60vw;
  height: 100vh;
}

.zoom-controls {
  margin-top: 10px;
  z-index: top;
}

.zoom-controls button {
  margin-right: 5px;
}

li.nav-item {
  text-align: -webkit-center;
}

li.nav-item .nav-link {
  height: 55px;
  width: 70px;
  transition: all 0.3s;
  margin: 0;
  padding: 0;
}

.z-top {
  z-index: 1;
}

.selected-classroom {
  fill: red;
}

.pointer {
  fill: green !important;
}

.pointer-text {
  font-size: 50px;
}

.align-baseline-middle {
  vertical-align: -webkit-baseline-middle;
}

.map-info {
  display: block;
  height: 35px;
}

.border-white-thick {
  border: 3px solid white;
}

.invert-1 {
  filter: invert(1);
}

.r-0 {
  right: 0;
}

.trans-3 {
  transition: all 0.3s;
}

.fs-100 {
  font-size: 100px;
}

@media (max-width: 425px) {
  .floor-img {
    max-height: 150px;
  }
  li.nav-item .nav-link {
    max-width: 20vw;
  }
}

@media (max-width: 375px) {
  nav.navbar div.container {
    padding: 0;
  }
}


.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 95, 133, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.modal.popup {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border: 1px solid black;
  max-height: 75vh;
  height: auto;
}

.header-btn {
  height: 40px;
  width: 40px;
}

.top-5 {
  top: 5rem;
}

.left-05 {
  left: 0.5rem;
}

.right-05 {
  right: 0.5rem;
}

.modal.search {
  left: auto;
  height: auto;
}

.modal {
  position: fixed;
  z-index: 10000;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 95vw;
  overflow: auto;
}

.close-button {
  margin-bottom: 10px;
}

.sidebar {
  position: fixed;
  top: 0;
  left: -280px;
  width: 280px;
  height: 100vh;
  background-color: #f8f8f8;
  transition: left 0.3s ease;
  z-index: 100;
  padding-top: 5rem;
}

.sidebar.open {
  left: 0;
}

.sidebar ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidebar ul li {
  padding: 10px;
}

.overlay-sidebar {
  z-index: 99;
}

.toggled {
  transform: rotateY(180deg)
}

.toggle {
  cursor: pointer;
}

.sidebar-toggle img {
  width: 30px;
  height: auto;
}

@keyframes jump {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-20px); }
}

@keyframes changeBackground {
  0% { background-color: red; }
  33% { background-color: green; }
  66% { background-color: blue; }
  100% { background-color: red; }
}

.pointer-text {
  width: 50px;
  height: 50px;
  background-color: red;
  animation: jump 1s 5;
}

.pointer {
  cursor: pointer;
  width: 200px;
  height: 200px;
  animation: changeBackground 9s infinite;
}

.react-transform-wrapper {
  background-color: transparent;
  background-image: linear-gradient(0deg, transparent 24%, rgba(0, 0, 0, .05) 25%, rgba(0, 0, 0, .05) 26%, transparent 27%, transparent 74%, rgba(0, 0, 0, .05) 75%, rgba(0, 0, 0, .05) 76%, transparent 77%, transparent), linear-gradient(90deg, transparent 24%, rgba(0, 0, 0, .05) 25%, rgba(0, 0, 0, .05) 26%, transparent 27%, transparent 74%, rgba(0, 0, 0, .05) 75%, rgba(0, 0, 0, .05) 76%, transparent 77%, transparent);
  height: 100%;
  background-size: 50px 50px;
}

#root {
  overflow: hidden;
}

.language-selector select {
  width: 50px;
  height: 35px;
  border-radius: 0px;
}